.option-input-item {
  @extend .mb-5;
}

.option-input-item-input-container {
  display: flex;
  align-items: center;
  & > * {
    &:not(:last-child) {
      @extend .me-5;
    }
  }
  &__select-input {
    flex-grow: 1;
  }
  &__number-input {
    width: 7rem;
  }
  &__order-input {
    width: 5rem;
  }
  &__checkbox-input {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    margin-top: 5px !important;
    label {
      margin-bottom: 0.5rem !important; 
      text-align: center; 
    }
  }
}

.check-opt {
  margin-top: -20px !important;
}