.badge-row-container {
  flex-direction: 'row';
  display: flex;
}

.badge-currency {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.badge-value {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.badge-container {
  min-width: 100px;
}

.badge-value-product-price {
  @extend .badge-value;
  color: rgb(161, 160, 160);
}

.badge-currency-product-price {
  @extend .badge-currency;
  background-color: #3d3d3d;
  color: rgb(166, 165, 165);
}
