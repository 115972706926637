@import 'grapesjs/src/styles/scss/main.scss';

.gjs-ticket-template-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @extend .rounded;

  #gjs {
    @extend .border;
  }

  .gjs-cv-canvas {
    top: 0;
    width: 100%;
    height: 100%;
  }

  .gjs-one-bg {
    @extend .bg-body;
  }

  .editor-row {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-grow: 1;
    min-height: 0;
  }

  .editor-canvas {
    flex-grow: 1;
  }

  .topbar-right {
    flex-basis: 230px;
    display: flex;
    justify-content: flex-end;
  }

  
  .gjs-field-static-text {
    margin-right: calc(100% - 150px); 
    // text-align: center; 
    font-size: 10px; 
    background-color: #17181c; 
  }
  .gjs-field-concat-text {
    margin-right: calc(100% - 150px); 
    // text-align: center; 
    font-size: 1px; 
    background-color: #17181c; 
  }



  .panel__left {
    flex-basis: 200px;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    @extend .bg-body;

    .gjs-blocks-c {
      display: flex;

      .gjs-block {
        width: 50%;
        margin: 0;
        padding: 0;
        border: none;

        .gjs-block-label {
          display: none;
        }

        &__media {
          all: unset;
          margin: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .panel__right {
    flex-basis: 250px;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .panel__custom-actions {
    flex-grow: 1;
  }

  .panel__devices {
    position: initial;
  }

  .panel__top {
    padding: 0;
    width: 100%;
    z-index: 0;
    display: flex;
    position: initial;
    justify-content: center;
    justify-content: space-between;

    & > * {
      position: initial;
    }
  }

  .panel__basic-actions {
    display: flex;
  }

  .panel__switcher {
    position: initial;
  }

  .layers-container {
    overflow: auto;
  }

  * {
    box-sizing: revert;
  }
}
