@font-face {
  font-family: 'f1-bold';
  src: url('/_metronic/assets/fonts/f1-display-bold.ttf');
}

@font-face {
  font-family: 'f1-regular';
  src: url('/_metronic/assets/fonts/f1-display-regular.ttf');
}

.ff-f1 {
  &-regular {
    font-family: 'f1-regular';
  }

  &-bold {
    font-family: 'f1-bold';
  }
}

@font-face {
  font-family: 'Canela-Regular';
  src: url('https://investopia-pub.solaax.cloud/INVESTOPIA/ab8791719a64fbb316efc893a0b706f7.ttf');
}
