.travel-logo-left {
  margin-left: 15px !important;
}
.travel-logo-right {
  margin-right: 15px !important;
}

.bg-body-custom {
  // background-color: #1f3fc094 !important;
  background-color: #4668fd94 !important;
}

.custom-frm-inv {
  font-family: 'Canela-Regular';
  // font-family: 'calibri-regular' !important;
  .form-control.form-control-solid,
  .file-grid-drop-area,
  .drag-drop-file-input-drop-area,
  .pin-input__display,
  .form-control-solid.form-date-input,
  .searchable-select-input {
    background-color: #ffff !important;
    border-color: #121418 !important;
    color: #000000 !important;
  }
  .form-control[readonly],
  [readonly].file-grid-drop-area,
  [readonly].drag-drop-file-input-drop-area,
  [readonly].pin-input__display,
  [readonly].form-date-input,
  [readonly].searchable-select-input {
    background-color: #aba9a9 !important;
  }
  .form-select.form-select-solid {
    background-color: #ffff;
    border-color: #121418;
    color: #000000;
  }

  .btn.btn-light-primary,
  .pin-input-row > button.btn-light-primary {
    color: #ffff !important;
    background-color: #031e55 !important;
  }
  .btn-check:checked + .btn.btn-light-primary,
  .pin-input-row > .btn-check:checked + button.btn-light-primary,
  .btn-check:active + .btn.btn-light-primary,
  .pin-input-row > .btn-check:active + button.btn-light-primary,
  .btn.btn-light-primary:focus:not(.btn-active),
  .pin-input-row > button.btn-light-primary:focus:not(.btn-active),
  .btn.btn-light-primary:hover:not(.btn-active),
  .pin-input-row > button.btn-light-primary:hover:not(.btn-active),
  .btn.btn-light-primary:active:not(.btn-active),
  .pin-input-row > button.btn-light-primary:active:not(.btn-active),
  .btn.btn-light-primary.active,
  .pin-input-row > button.btn-light-primary.active,
  .btn.btn-light-primary.show,
  .pin-input-row > button.btn-light-primary.show,
  .show > .btn.btn-light-primary,
  .pin-input-row.show > button.btn-light-primary {
    color: #ffffff;
    background-color: #031e55 !important;
  }

  .btn.btn-light-info,
  .pin-input-row > button.btn-light-info {
    color: #fff;
    border-color: #2f264f;
    background-color: gray !important;
  }
  btn-check:checked + .btn.btn-light-info,
  .pin-input-row > .btn-check:checked + button.btn-light-info,
  .btn-check:active + .btn.btn-light-info,
  .pin-input-row > .btn-check:active + button.btn-light-info,
  .btn.btn-light-info:focus:not(.btn-active),
  .pin-input-row > button.btn-light-info:focus:not(.btn-active),
  .btn.btn-light-info:hover:not(.btn-active),
  .pin-input-row > button.btn-light-info:hover:not(.btn-active),
  .btn.btn-light-info:active:not(.btn-active),
  .pin-input-row > button.btn-light-info:active:not(.btn-active),
  .btn.btn-light-info.active,
  .pin-input-row > button.btn-light-info.active,
  .btn.btn-light-info.show,
  .pin-input-row > button.btn-light-info.show,
  .show > .btn.btn-light-info,
  .pin-input-row.show > button.btn-light-info {
    background-color: gray !important;
  }

  .btn.btn-light-success,
  .pin-input-row > button.btn-light-success {
    color: #0bb783;
    border-color: #1c3238;
    background-color: #042975 !important;
  }

  .btn.btn-bg-light,
  .pin-input-row > button {
    color: #042975 !important;
    border-color: #121418;
    background-color: #fff;
  }
  .btn.btn-bg-light {
    .svg-icon svg [fill]:not(.permanent):not(g) {
      transition: fill 0.3s ease;
      fill: blue;
    }
  }

  .btn.btn-light-dark,
  .pin-input-row > button.btn-light-dark {
    color: pink;
    border-color: #1c3238;
    background-color: #042975;
  }
  .btn.btn-light-dark {
    .svg-icon svg [fill]:not(.permanent):not(g) {
      transition: fill 0.3s ease;
      fill: #fff;
    }
  }
  .btn-check:checked + .btn.btn-light-dark,
  .pin-input-row > .btn-check:checked + button.btn-light-dark,
  .btn-check:active + .btn.btn-light-dark,
  .pin-input-row > .btn-check:active + button.btn-light-dark,
  .btn.btn-light-dark:focus:not(.btn-active),
  .pin-input-row > button.btn-light-dark:focus:not(.btn-active),
  .btn.btn-light-dark:hover:not(.btn-active),
  .pin-input-row > button.btn-light-dark:hover:not(.btn-active),
  .btn.btn-light-dark:active:not(.btn-active),
  .pin-input-row > button.btn-light-dark:active:not(.btn-active),
  .btn.btn-light-dark.active,
  .pin-input-row > button.btn-light-dark.active,
  .btn.btn-light-dark.show,
  .pin-input-row > button.btn-light-dark.show,
  .show > .btn.btn-light-dark,
  .pin-input-row.show > button.btn-light-dark {
    color: #fff;
    // border-color: #fff;
    background-color: #042975 !important;
  }

  .react-date-picker__inputGroup {
    color: #000000 !important;
    input {
      color: #000000 !important;
    }
  }
  .form-label,
  .file-grid__text-label,
  .drag-drop-file-input__text-label {
    color: #ffff !important;
  }
}

.reg-bio {
  .bg-body {
    background-color: #031e55 !important;
  }
}

@media (max-width: 575px) {
  .blck-box {
    width: 450px !important;
  }
}

@media (max-width: 495px) {
  .blck-box {
    width: 400px !important;
  }
}

@media (max-width: 435px) {
  .blck-box {
    width: 350px !important;
  }
}

@media (max-width: 374px) {
  .blck-box {
    width: 320px !important;
  }
}

@media (max-width: 343px) {
  .blck-box {
    width: 300px !important;
  }
}
