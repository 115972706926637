//cell
.rbc-off-range-bg {
    background: #eaf6ff17 !important;
}
.rbc-today {
    background-color: rgba(43, 182, 159, 0.308) !important;
}

//timeslot cell
.rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #c3bbbb00 !important;
}
.rbc-timeslot-group {
    border-bottom: 1px solid #dddddd1a !important;
}

//show more label
.rbc-show-more {
    color: #000 !important;
    width: 25% !important;
}

//toolbar button label
.rbc-toolbar button {
    color: #656c70 !important; 
}

//toobar button spacing
.rbc-btn-group {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-top: 8px !important;
}

//toobar month label
.rbc-toolbar .rbc-toolbar-label {
    font-weight: bolder !important;
}

//event rendered plot
.rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: rgba(25, 137, 150, 0.7) !important;
}