.retailProductCard {
  height: 350px;
  width: 350px;
  background-color: #fff;
  border-radius: 0.25rem;
  overflow: hidden;
  margin-right: 40px;
  margin-bottom: 40px;
}

.eventPageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding: 50px 9%;
}

.loaderContainer {
  background-color: #fff;
  justify-content: end;
  align-items: end;
  margin-left: 40px;
}

.overlay {
padding-top: 20px;  
padding-left: 10px;
padding-right: 10px;
}

.cardTitle {
  font-size: 1.25rem;
  color: white;
}

.cardDescription {
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
  height: 50px;
}

.emptyImg {
  display: block;
  width: 100%;
  height: 230px;
}