.event {
  border-radius: 0 !important;
}

.eventStart {
  border-bottom-left-radius: 10rem !important;
  border-top-left-radius: 10rem !important;
}

.eventEnd {
  border-bottom-right-radius: 10rem !important;
  border-top-right-radius: 10rem !important;
}
